import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useGoogleApi } from "context/google";

const PlacesAutocomplete = ({ onPlaceChanged, ...rest }) => {

  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const { google } = useGoogleApi();

  const options = {
    componentRestrictions: { country: ["us", "gu"] },
    fields: ["address_components", "geometry"],
    types: ["address"],
  };

  useEffect(() => {
    if (!google) return;

    const autocompleteInput = document.getElementById("places-autocomplete");

    const observer = new MutationObserver(function () {
      observer.disconnect();
      $("#places-autocomplete").attr("autocomplete", "disabled");
    });

    observer.observe(autocompleteInput, {
      attributes: true,
      attributeFilter: ['autocomplete']
    });

    autoCompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      onPlaceChanged && onPlaceChanged(place);
    });
  }, []);

  return <input id="places-autocomplete" autoComplete="disabled" ref={inputRef} className="form-control" {...rest} />

}

PlacesAutocomplete.propTypes = {
  onPlaceChanged: PropTypes.func,
}

export default PlacesAutocomplete;